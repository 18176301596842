a.button {
  color: #fff;
  border-radius: 2px;
  margin-right: 15px;
  padding: 8px 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  text-decoration: none;
  display: inline-block;
}

a.button.transparent {
  background-color: #0000;
  border: 1px solid #fff;
}

a.button.red {
  background-color: #e52c34;
  border: 1px solid #e52c34;
}

a.button.dark {
  color: #05174b;
  background-color: #0000;
  border: 1px solid #05174b;
}

a.button.dark.bracket:after {
  filter: invert();
}

a.button.bracket:after {
  content: " ";
  contain: content;
  background-image: url("bracket.32b59a6b.svg");
  width: 5px;
  height: 8px;
  margin-left: 14px;
  display: inline-block;
  position: relative;
  bottom: 4px;
}
/*# sourceMappingURL=index.a1b92cc9.css.map */

@import '/packages/site/src/public/styles/colors.scss';

a.button {
  display: inline-block;
  padding: 8px 20px;
  text-decoration: none;
  border-radius: 2px;
  color: $ui_01;
  margin-right: 15px;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;

  &.transparent {
    background-color: transparent;
    border: 1px solid $ui_01;
  }
  
  &.red {
    background-color: $accent_01;
    border: 1px solid $accent_01;
  }

  &.dark {
    background-color: transparent;
    border: 1px solid $text_01;
    color: $text_01;

    &.bracket::after {
      filter: invert(1);
    }
  }

  &.bracket::after {
    content: " ";
    contain: content;
    width: 5px;
    height: 8px;
    position: relative;
    display: inline-block;
    margin-left: 14px;
    background-image: url(/packages/site/src/assets/images/bracket.svg);
    bottom: 4px;
  }
}